import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import styled from 'styled-components';
import Image from './Image';


const StyledHeader = styled.div`
    padding: 20px;
    
    margin-bottom: 40px;
`;

const LogoWrapper = styled.div`
    width: 200px;
    
    cursor: pointer;
`;


const Header = () => {
    const goHome = useCallback(() => {
        navigate('/');
    }, []);

    return (
        <StyledHeader>
            <LogoWrapper onClick={goHome}>
                <Image filename="shapelabs-logo-header"/>
            </LogoWrapper>
        </StyledHeader>
    );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
