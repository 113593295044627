import React, { useCallback } from 'react';

import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import GatsbyImg from 'gatsby-image';

import styled from 'styled-components';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es';

import { scrollTo } from '../util/scroll';


const StyledFooter = styled.div`
    width: 100%;
    
    padding: 40px 100px;
    
    @media screen and (max-width: 1440px) {
      padding: 40px 7vw;
    }
    
    margin-top: auto;
    
    font-family: "Roboto", sans-serif;
`;

const IconWrapper = styled.div`
    cursor: pointer;
`;


const Columns = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 48px 32px;
    
    @media screen and (max-width: 860px) {
      grid-template-columns: repeat(2, minmax(160px, 1fr));
    }
    
    @media screen and (max-width: ${props => props.theme.bps.mbl}) {
      grid-template-columns: repeat(1, minmax(160px, 1fr));
    }
    
    margin: 34px 0 88px 0;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;
const ColumnTitle = styled.span`
    font-size: 16px;
    font-weight: bold;
    
    ${props => props.onClick && 'cursor: pointer;'}
    &:hover {
      ${props => props.onClick && `color: ${props.theme.blackHover};`}
    }
`;
const ColumnEntry = styled.span`
    font-size: 16px;
    
    & a {
      text-decoration: none;
      color: ${props => props.theme.black};
      
      &:hover {
        color: ${props => props.theme.blackHover};
      }
    }
`;

const ExternalLinkIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.blackHover};
    
    font-size: 12px;
    margin-left: 5px;
`;
const ExternalLink = ({ children, to, ...props }) =>
    <a href={to} {...props} target="_blank">
        {children}
        <ExternalLinkIcon icon={faExternalLinkAlt} />
    </a>
;

const Copyright = styled.div``;


const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            images: allFile(filter: { extension: { regex: "/jpe?g|png|gif/" } }) {
                nodes {
                    name
                    childImageSharp {
                        fixed(width: 65) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            projects: allAirtable(filter: {table: {eq: "Projects"}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        name: Project_Name
                        slug
                    }
                }
            }
            labs: allAirtable(filter: {table: {eq: "Labs"}}, sort: {fields: data___order}) {
                nodes {
                    id
                    data {
                        name: Name
                        link: Read_about_it_on_Medium_link
                    }
                }
            }
        }
    `);

    const img = data.images.nodes.find(image => image.name === 'shapelabs-icon-large');

    const projects = data.projects.nodes;
    const labs = data.labs.nodes;

    const goHome = () => {
        if (window && window.location.pathname === '/') {
            scrollTo('top');
        } else {
            navigate('/');
        }
    };

    const scrollers = {
        projects: useCallback(() => {
            scrollTo('projects');
        }, []),
        labs: useCallback(() => {
            scrollTo('labs');
        }, []),
    };

    return (
        <StyledFooter>
            <IconWrapper onClick={goHome}>
                <GatsbyImg fixed={img.childImageSharp.fixed} />
            </IconWrapper>

            <Columns>
                <Column>
                    <ColumnTitle>Contact us</ColumnTitle>
                    <ColumnEntry><a href="mailto:hello@shapelabs.co">hello@shapelabs.co</a></ColumnEntry>
                </Column>

                {projects && (
                    <Column>
                        <ColumnTitle onClick={scrollers.projects}>Projects</ColumnTitle>
                        {projects.map(({ id, data }) => (
                            <ColumnEntry key={id}>
                                <Link to={`projects/${data.slug}`}>
                                    {data.name}
                                </Link>
                            </ColumnEntry>
                        ))}
                    </Column>
                )}

                {labs && (
                    <Column>
                        <ColumnTitle onClick={scrollers.labs}>Labs</ColumnTitle>
                        {labs.map(({ id, data }) => (
                            <ColumnEntry key={id}>
                                <ExternalLink to={data.link}>
                                    {data.name}
                                </ExternalLink>
                            </ColumnEntry>
                        ))}
                    </Column>
                )}

                <Column>
                    <ColumnTitle>Follow us</ColumnTitle>
                    <ColumnEntry><ExternalLink to="https://medium.com/shapelabs">Medium</ExternalLink></ColumnEntry>
                </Column>
            </Columns>

            <Copyright>&copy; Shape Laboratories Limited {new Date().getFullYear()}</Copyright>
        </StyledFooter>
    );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
