import { graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import Footer from './Footer';
import Header from './Header'

import './global.css';


const theme = {
    primary: '#1abc9c',
    white: '#ffffff',
    black: '#000000',
    darkGrey: '#4a4a4a',
    blackHover: '#777777',

    bps: {
        md: '768px',
        mbl: '425px',
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Main = styled.div`
  margin: 0 15%;
  
  max-width: 1060px;
  
  @media screen and (max-width: ${props => props.theme.bps.mbl}) {
    margin: 0 20px;
  }
  
  @media screen and (min-width: calc(1060px * 1.3)) {
    margin: 0 auto;
  }
`;

const Layout = ({ children, projectColour }) => (
    <ThemeProvider theme={{...theme, project: projectColour}}>
        <Container id="top">
            <Header/>

            <Main>{children}</Main>

            <Footer />
        </Container>
    </ThemeProvider>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    projectColour: PropTypes.string,
};

Layout.defaultProps = {
    projectColour: theme.primary,
};

export default Layout;
