import { scroller } from 'react-scroll/modules/index';


export const scrollTo = (anchor, options) => {
    scroller.scrollTo(anchor, {
        duration: 500,
        smooth: 'easeInOutQuad',
        offset: -60,
        ...options,
    });
};
