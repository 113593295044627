import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';


// thx https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05

const Image = ({ filename }) => {
  const { images } = useStaticQuery(graphql`
      query {
          images: allFile(filter: { extension: { regex: "/jpe?g|png|gif/" } }) {
              nodes {
                  name
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid
                      }
                  }
              }
          }
      }
  `);

  const img = images.nodes.find(image => image.name === filename);

  return img ?
      <Img fluid={img.childImageSharp.fluid}/>:
      <div/>;  // TODO: better fallback
};

export default Image;
